import ACTIONS from './actions';

const initialState = {
	currentUser: null,
	users: null,
	error: null,
};

function setCurrentUser(state, action) {
	let { payload: {
		currentUser = null,
	} } = action;

	return {
		...state,
		currentUser,
	}
}


function onFail(state, action) {
	let { payload: { error = null } } = action;
	return {
		...state,
		error,
	}
}

export default (state = initialState, action) => {
	let { type } = action;

	switch (type) {
		case ACTIONS.USER:
			return setCurrentUser(state, action)
		case ACTIONS.FAILED:
			return onFail(state, action);
		default:
			return state;
	}
};
