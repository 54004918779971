export default {
	ROOT: '/',
	HOME: '/home',
	LOGIN: '/login',
	REGISTER_NEW: '/register-new',
	FORGOT_PASSOWORD: '/forgot-password',
	FORGOT_PASSOWORD_CONFIRM: '/forgot-password-confirm',
	RESET_PASSOWORD: '/reset-password',
	RESET_PASSOWORD_SUCCESS: '/reset-password-success',
	PROFILE: '/profile',
	APP_CONFIG: '/app-config',
	RATES: '/rates',
	AGENTS: '/agents',
}
