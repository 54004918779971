import {
	combineReducers,
	applyMiddleware as applyReduxMiddleware,
	createStore as createReduxStore,
} from 'redux';
import { composeWithDevTools as composeWithReduxDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";

import { default as appReducer } from './app'
import { default as notificationReducer } from './Notification'


const ActionLogger = store => next => action => {
	btLog('DEV Action Logger:', action);
	return next(action);
};

export default ({ apiService }) => {

	const middlewares = [];

	middlewares.push(
		reduxThunk.withExtraArgument({
			apiService: apiService || Error('ApiService is not defined.'),
		}),
	);

	if (BT.config.env === "development") {
		middlewares.push(ActionLogger);
	}

	return createReduxStore(
		combineReducers({
			app: appReducer,
			notification: notificationReducer,
		}),
		// Todo: For production remove Redux DevTools
		composeWithReduxDevTools(
			applyReduxMiddleware(...middlewares),
		),
	);
}
