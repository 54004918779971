export default class {

	/**
	 * Store string value in system local storage
	 * @param {String} key
	 * @param {String} value
	 * @returns {void}
	 * @public
	 */
	static save(key, value) {
		localStorage.setItem(key, value);
	}

	/**
	 * Store an object into system local storage
	 * @param {String} key
	 * @param {Object} value
	 * @returns {void}
	 * @public
	 */
	static saveObject(key, value) {
		if (typeof value === 'object') {
			this.save(key, JSON.stringify(value));
		}
	}

	/**
	 * Load the value stored from system local storage
	 * @param {String} key
	 * @returns {String|null}
	 * @public
	 */
	static load(key) {
		//return localStorage.has(key) ? localStorage.getItem(key) : null;
		return localStorage.getItem(key) || null;
	}

	/**
	 * Load an object from system local storage.
	 * @param {String} key
	 * @returns {Object|null}
	 * @public
	 */
	static loadObject(key) {
		let res = this.load(key);
		return res ? JSON.parse(res) : null;
	}

	/**
	 * Remove a value from system local storage
	 * @param {String} key
	 * @returns {void}
	 * @public
	 */
	static remove(key) {
		localStorage.removeItem(key);
	}

	/**
	 * Clear all stored keys from system local storage
	 * @returns {void}
	 * @public
	 */
	static clear() {
		localStorage.clear();
	}
}
