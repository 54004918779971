import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { connect as connectWithRedux } from 'react-redux';

import InternalLinks from 'enums/InternalLinks';
import AsyncComponent from 'components/Base/AsyncComponent';

const Pages = {
	NotFound_404: AsyncComponent(() => import('pages/NotFound')),
	Home: AsyncComponent(() => import('pages/Home')),
	Login: AsyncComponent(() => import('pages/Auth/Login')),
	RegisterNew: AsyncComponent(() => import('pages/Auth/Register')),
	ForgotPassword: AsyncComponent(() => import('pages/Auth/ForgetPassword')),
	ForgotPasswordConfirm: AsyncComponent(() => import('pages/Auth/ForgetPasswordConfirm')),
	ResetPassword: AsyncComponent(() => import('pages/Auth/ResetPassword')),
	ResetPasswordSuccess: AsyncComponent(() => import('pages/Auth/ResetPasswordSuccess')),
	Profile: AsyncComponent(() => import('pages/Profile')),
	AppConfig: AsyncComponent(() => import('pages/AppConfig')),
	Agents: AsyncComponent(() => import('pages/Agents')),
	Rates: AsyncComponent(() => import('pages/Rates')),
}

function App(props) {
	const { currentUser } = props,
		appToken = localStorage.getItem('TOKEN'),
		isLoggedIn = !!(appToken && currentUser),
		PrivateRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={props => (
				isLoggedIn ? <Component {...props} />
					: <Redirect to={{ pathname: InternalLinks.LOGIN, state: { from: props.location } }} />
			)} />
		);

	btLog(`Is Logged In: ${isLoggedIn}, Token: ${appToken}`, currentUser);

	return (
		<Router basename={BT.config.basename}>
			<Switch>
				<PrivateRoute exact path={InternalLinks.ROOT} component={Pages.Profile} />
				<PrivateRoute exact path={InternalLinks.HOME} component={Pages.Profile} />
				<PrivateRoute path={InternalLinks.PROFILE} component={Pages.Profile} />
				<PrivateRoute path={InternalLinks.APP_CONFIG} component={Pages.AppConfig} />
				<PrivateRoute path={InternalLinks.AGENTS} component={Pages.Agents} />
				<PrivateRoute path={InternalLinks.RATES} component={Pages.Rates} />
				<Route path={InternalLinks.LOGIN} component={Pages.Login} />
				<Route path={InternalLinks.REGISTER_NEW} component={Pages.RegisterNew} />
				<Route path={InternalLinks.FORGOT_PASSOWORD} component={Pages.ForgotPassword} />
				<Route path={InternalLinks.FORGOT_PASSOWORD_CONFIRM} component={Pages.ForgotPasswordConfirm} />
				<Route path={InternalLinks.RESET_PASSOWORD} component={Pages.ResetPassword} />
				<Route path={InternalLinks.RESET_PASSOWORD_SUCCESS} component={Pages.ResetPasswordSuccess} />

				{/*
				<Route path={'/view/:type/:fileId'} component={Pages.View} />
				<Route
					path={INTERNAL_LINKS.REVIEWS}
					render={props => (
						<Pages.LookerExplore
							{...props}
							module={MODULES.REVIEW}
							link={LOOKER_LINKS.Dashboards.REVIEWS}
						/>
					)}
				/>
				 */}
				<Route path={'*'} component={Pages.NotFound_404} />
			</Switch>
		</Router>
	)
}

const mapStateToProps = state => {
	let { app: { currentUser } } = state;
	return { currentUser }
}

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		signIn: (data) => dispatch(Actions.signInUser(data))
// 	}
// }

export default connectWithRedux(
	mapStateToProps,
	null
)(App);

