const ENV = process.env;
/**
 * @global {Object} BT
 */
var BT = {
	config: {
		debug: (ENV.REACT_APP_DEBUG === 'true'),
		env: ENV.NODE_ENV,
		basename: ENV.REACT_APP_BASENAME,
		apiUrl: ENV.REACT_APP_API_URL,
	},
	lib: {},
	services: {}
}

/**
 * Bendigi Logger
 * @param  {...any} args
 * @global {Function} btLog
 */
var btLog = (...args) => {
	if (BT.config.debug) {
		console.log(...args)
	}
}

global['BT'] = BT;
global['btLog'] = btLog;
