import Axios from 'axios';

/**
 *
 * @param {Object} opt
 * @param {String} [opt.method]
 * @param {String} [opt.url] Base url.
 * @param {String} opt.path request path.
 * @param {Object} [opt.token] Bendigi API token.
 * @param {Object} [opt.headers] Http headers
 * @param {Object} [opt.data] Post data
 * @returns {Promise<Object|null>}
 */
async function apiCall(opt) {
	let config = {
		method: opt.method || 'GET',
		baseURL: opt.url,
		url: opt.path,
		...opt.headers ? { headers: { ...opt.headers } } : null,
		...opt.data ? { data: opt.data } : null,
	},
		result = null;

	try {
		// @ts-ignore
		result = await Axios(config).then(response => response.data);
	} catch (error) {
		let { status = null, data = null } = error.response || {},
			message = status ? data.error.message : `Failed to connect to Bendigi Authentication Service @ ${config.baseURL}`;

		throw new Error(message);
	}

	// Todo: Remove this before publishing
	btLog('DATA', result);

	return (result && result.success) ? result.result : null;
}


export default class ApiService {

	constructor() {
		this._accessToken = null
	}

	get accessToken() { return this._accessToken; }
	set accessToken(val) { this._accessToken = val; }

	async request({ method, path, data }, isJson = true) {
		const API_BASE = BT.config.apiUrl;

		let headers = this.accessToken ? {
			...isJson ? {
				'Content-Type': 'application/json',
			} : null,
			'Authorization': `Bearer ${this.accessToken}`
		} : null;
		// uploadData = data ? (isJson ? JSON.stringify(data) : data) : null;

		btLog(`Making API CAll to ${path}`, data);

		return await apiCall({
			method,
			url: API_BASE,
			headers,
			path,
			data,
		});
	}
}
