import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

// import 'bendigi';
import './bendigi';
import 'BT';

// import 'assets/output.css';
// import 'modern-normalize/modern-normalize';

import './index.scss';
import appStore from './store';
import App from './App';


ReactDOM.render(
	<ReduxProvider store={appStore}>
		<StrictMode>
			<App />
		</StrictMode>
	</ReduxProvider>
	, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
