
export const App = {
	FAILED: 'failed',
};

export const Notification = {
	NOTIFICATION: 'notifcation',
	NOTIFICATION_DISMISS: 'notifcation:dismiss',
}

export default {
	App,
	Notification,
}
