import { Notification as ACTIONS } from '../actions';

const initialState = {
	messages: [],
};

function setNotification(state, action) {
	let { messages = [] } = state,
		{ payload: {
			message = null,
		} } = action,
		newMessages = [...messages];

	if (message) {
		newMessages.push(message);
	}

	return {
		...state,
		messages: newMessages,
	}
}

function dismissNotification(state, action) {
	let { messages = [] } = state,
		{ payload: {
			messageId = null,
		} } = action,
		newMessages;

	if (messageId) {
		newMessages = messages.filter(item => item.id !== messageId)
	}

	return {
		...state,
		messages: newMessages,
	}
}

export default (state = initialState, action) => {
	let { type } = action;

	switch (type) {
		case ACTIONS.NOTIFICATION:
			return setNotification(state, action);
		case ACTIONS.NOTIFICATION_DISMISS:
			return dismissNotification(state, action)
		default:
			return state;
	}
};
